<template>
  <v-layout id="item" d-flex column  class="fh">
    <v-flex shrink>
      <v-breadcrumbs
      :items="breadcrumbs"
      >
      
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            @click.prevent="$router.replace(item.href)"
            :disabled="item.disabled"
          >
          <v-icon v-if="item.icon" size="20" color="primary">{{item.icon}}</v-icon>
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-divider></v-divider>
      <v-progress-linear :indeterminate="loading"></v-progress-linear>
    </v-flex>
    <v-flex grow class="relative body" style="z-index: 0" >
      <div class="absolute fh fw  " >
        <div class="relative fh fw auto-y-overflow " ref="wraper">
            <v-row class="fh ma-0"> 
              <v-col cols="12" sm="8">
                  <VFormBuilder ref="form" :fields="fields" 
                  
                  :defaultsFieldsProps="{
                          rounded: true,
                          dense: true,
                          outlined: true
                      }" 
                  :disabled="loading" />
              </v-col>
            </v-row>
        </div>
      </div>
    </v-flex>
    <v-flex shrink >
      <v-divider></v-divider>

      <v-toolbar dense flat >
        <v-btn  v-if="isNew" outlined rounded color="success" class="mx-1" @click="submit(true)" :loading="loading" >
          {{ 'save_and_new' | t }}
        </v-btn>
        <v-btn  outlined rounded color="info" class="mx-1" @click="submit(false)" :loading="loading" >
          {{ 'save' | t }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn  outlined rounded color="grey" class="mx-1" :disabled="loading" @click="$router.go(-1)" >
          {{ 'cancel' | t }} 
        </v-btn>
      
      </v-toolbar>
    </v-flex>
  </v-layout>
</template>

<script>
 
import VFormBuilder from '@/components/VFormBuilder'
export default {
  name: 'DocView',
  components: {
      VFormBuilder
  },
  data: () => ({
    update: 0,
    loading: false,
    document: {},
  }),
  watch: {
    '$route': {
      handler () {
        if (this.isNew)
          return
        
        this.loading = true
        this.docRef.get().then(doc=> {
          const data = doc.data()
          this.fields.forEach(field => {
            this.$set(this.document, field.name, data[field.name])
          });
        }).catch(this.handelError).finally(() => this.loading = false)


     

      },
      immediate: true
    }
  },
  computed: {
    breadcrumbs () {
      if (!this.documentSettings) return

        const { collection, documentSettings } = this 
        const { collectionName, newDocName, nameField } = documentSettings
       
      return [
        {
          icon: 'mdi-home',
          disabled: false,
          href: '/dashboard',
        },
        {
          text: this.$t(collectionName),
          disabled: false,
          href: '/dashboard/'+collection,
        },
        {
          text: this.isNew ? this.$t(newDocName) : nameField(this.document),
          disabled: true,
        },
      ]
    }, 
    collection () {
      return this.$route.params.collection 
    },
    docId () {
      this.update
      return this.$route.params.docId 
    },
    collectionRef  () {
      return this.database.collection(this.collectionsSettings.collectionPath(this))
    },
    docRef() {
      const { collectionRef } = this
      return  this.isNew ? collectionRef.doc() : collectionRef.doc( this.docId )
    },
    isNew () {
      return this.docId.trim().toLowerCase() == 'new'
    },
    settings () {
      let settings
      try {
            const collection = require('@/collections/' + this.collection)
            if (collection)
                settings = collection.default
      } catch (e) {
            this.$router.replace('/404')
      }
      return settings 
    },
    documentSettings () {
      return this.settings ? this.settings.document : undefined
    },
    collectionsSettings () {
      
        return this.settings ? this.settings.collection : undefined
    },
 
    fields () {
      if (!this.documentSettings) return
        const { fields } = this.documentSettings
      return fields(this).map(field => {
        //set document value in edit
        if(!this.isNew && !this.loading) {
          field.attrs.value = this.document[field.name]
        }
        return field
      })
    }
  },
  methods: {
    newDoc () {
      const { form } = this.$refs
      form.resetValidation()
      form.init()
      this.update++ // update new docId
    },
    submit (addNew = false) {

      const { form } = this.$refs

      form.$once('submit', values => {

        const { uid } = this.currentUser
        const { nameField } = this.documentSettings
        values.uid = uid

        if (this.isNew)
          values.createdAt = new Date()
        else
          values.updatedAt = new Date()

        this.loading = true
        this.docRef.set(values, { merge: !this.isNew }).then(() => {
          this.notify(this.$t(this.isNew ? 'guest_created_successfuly': 'guest_updated_successfuly',nameField(values)), {
              color: 'success',
              icon: 'mdi-check-circle'
            })
          if (!addNew)
            this.$router.go(-1)
          else 
            this.newDoc()
        
        }).catch(this.handelError).finally(() => this.loading = false)
      })
      form.submit()
   
    

   
      
    } 
  }
}
</script>

<style lang="scss">
#item {
  .table-section {
    max-height: 48px; 
    flex-basis: 48px; 
    z-index: 1;
  }
  .table-section.table-footer {
    max-height: 58px; 
    flex-basis: 58px; 
  }
  .container {
    padding: 0;
    z-index: 1;
  }
  .v-skeleton-loader__table-tfoot {
    padding: 5px;
  }

  .v-data-footer.mobile {
    .v-data-footer__select,
    .v-data-footer__pagination {
      margin: auto;
    }
    .v-data-footer__select{

      height: 48px;
    }
  }
}
</style>